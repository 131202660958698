<template>
  <div class="register-wrap">
    <div class="success content_area">
      <p class="tc resuccess mb50">Registration successfully!</p>
      <p class="tc twosucc mb50">
        Please set up your available meeting time at first, then attend 1v1
        meeting
      </p>
      <div class="btn tc">
        <el-button
          type="primary"
          @click="$router.push('/console/conference/negotiationSetting')"
          >Time Management</el-button
        >
      </div>
      <div class="btn tc">
        <el-button size="small" type="default" @click="$router.push('/')"
          >Return Home</el-button
        >
      </div>
      <setTalkDialog ref="setTalk"></setTalkDialog>
    </div>
  </div>
</template>

<script>
import setTalkDialog from "~osm/components/talk/setTalk";

export default {
  components: {
    setTalkDialog,
  },
  data() {
    return {};
  },
  mounted() {
    //this.getList();
  },
  methods: {
    async getList() {
      let params = {
        meeting_id: this.MEETING_ID,
        user_id: this.USER_INFO.id,
        nopage: 1,
        is_self: true,
      };
      let res = await this.$store.dispatch(
        "baseConsole/getnegotationsetList",
        params
      );
      if (res.success) {
        let howHour = (res.total_time / 3600).toFixed(1);
        this.$store.commit("SET_TALK_DURATION", howHour);
        // if (this.TALK_DURATON < 10) {
        //   this.$refs.setTalk.hintVisibleShow(true);
        // } else {
        //   this.$refs.setTalk.hintVisibleShow(false);
        // }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.register-wrap {
  height: calc(100vh - 62px);
  background: #f6f7f9;
  padding-top: 23px;
}

.success {
  padding-top: 50px;
  background: #fff;
  padding-bottom: 50px;
  .resuccess {
    font-size: 18px;
    font-weight: 600;
  }
  .twosucc {
  }
}
.btn {
  margin-bottom: 30px;
}
.mb50 {
  margin-bottom: 30px;
}
</style>
